import React from 'react';
import Header from './components/Header';
import './App.css';
import TopMenu from "./components/TopMenu";
import { Routes, Route } from 'react-router-dom';
import FromTodayTiming from "./components/FromTodayTiming";
import FromTiming from "./components/FromTiming";
import NotFound from "./components/NotFound";
import FromLastMonth from "./components/FromLastMonth";
import FromDue from "./components/FromDue";
import Footer from "./components/Footer";
import { useAnalytics } from './components/UseAnalytics'

function App() {
    useAnalytics()
    return (
        <div className="App">
            <Header/>
            <Routes>
                <Route path="/" element={<TopMenu />} />
                <Route path="/from_today_timing" element={<FromTodayTiming />} />
                <Route path="/from_timing" element={<FromTiming />} />
                <Route path="/from_last_month" element={<FromLastMonth />} />
                <Route path="/from_due" element={<FromDue />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
