const ListMenu = () => {
    return (
        <div className="text-darkShade px-4 py-16 w-full">
            <div className="mx-auto w-11/12 p-5 md:w-6/12 lg:w-4/12">

                <h2 className="text-mainBrand mb-2 text-lg font-semibold">他の方法で出産予定日を調べる</h2>
                <ul className="list-none">
                    <li><a href="/from_today_timing" className="link">今日タイミングがとれた場合の出産予定日を調べる</a></li>
                    <li><a href="/from_timing" className="link">タイミングをとった日付から出産予定日を調べる</a></li>
                    <li><a href="/from_last_month" className="link">最終生理開始日から出産予定日を調べる</a></li>
                    <li><a href="/from_due" className="link">出産希望日から逆算する</a></li>
                </ul>
            </div>
        </div>
    )
}

export default ListMenu
