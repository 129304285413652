import DueDate from "../domain/DueDate";
import DateFormat from "../presentation/DateFormat";
import ListMenu from "./ListMenu";
import {Helmet} from "react-helmet-async";

const FromTodayTiming = () => {
    const today = new Date();
    const dueDate = DueDate.createFromTiming(today);

    const formattedDate = DateFormat.fullDate(dueDate.get());
    const pageTitle = '今日仲良ししたら出産予定日がいつになるか計算する。'

    // const dueDate = '2021年12月31日';
    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="w-full flex justify-center items-center">
                <div className="bg-neutral text-lightShade px-4 py-16 w-full">

                    <div className="bg-white mx-auto w-11/12 rounded-xl p-5 md:w-6/12 lg:w-4/12">
                        <h1 className="text-3xl font-bold tracking-tight mb-12">
                        <span className="block ">
                            今日、パートナーとタイミングがとれたら、
                            出産予定日は<br/><br/>{formattedDate}<br/><br/>ぐらいです。
                        </span>
                        </h1>
                    </div>

                </div>
            </div>
            <ListMenu/>
        </div>

    )
}

export default FromTodayTiming
