const Header = () => {
    return (
        <div className="navbar h-24">
            <div className="flex-1">
                <a href="/" className="btn btn-ghost normal-case text-xl h-fit font-mochiyPopOne ">
                    <img className="h-20 w-auto mr-2" src="/image/logo_221126.png" alt="" /> 出産予定日はいつ？
                </a>
            </div>
            <div className="flex-none">
            </div>
        </div>
);
}

export default Header;
