const TopMenu = () => {
    const cards = [
        {
            'title': '今日仲良ししたら',
            'img': '/image/think_baby.png',
            'url': 'from_today_timing',
        },
        {
            'title': '仲良しした日から',
            'img': '/image/timing_date.png',
            'url': 'from_timing',
        },
        {
            'title': '最後の生理開始日から',
            'img': '/image/calendar.png',
            'url': 'from_last_month',
        },
        {
            'title': '出産したい日から逆算',
            'img': '/image/kounotori.png',
            'url': 'from_due',
        },
    ];

    return (
        <div className="top-menu">
            <div className="w-full flex justify-center items-center">

                <div className="bg-neutral text-lightShade px-4 py-16 w-full grid grid-cols-2 gap-4 ">
                        {cards.map((card)=>{
                            return (
                            <a href={card.url}>
                                <div className="card bg-white text-primary-content">
                                    <div className="card-body text-darkShade">
                                        <h2 className="card-title">{card.title}</h2>
                                        <div className="h-16 text-center">
                                            <img src={card.img} className="h-16 w-auto inline" alt={card.title} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            )
                        })}

                </div>
            </div>

        </div>
);
}

export default TopMenu;
