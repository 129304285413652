class DueDate {
    private date: Date;

    private constructor(date: Date) {
        this.date = date;
    }

    public static createFromLastPeriodStartDate(lastPeriodStartDate: Date): DueDate {
        const dueDate = new Date(lastPeriodStartDate.getTime());
        dueDate.setDate(dueDate.getDate() + 280);
        return new DueDate(dueDate);
    }

    public static createFromTiming(timing: Date): DueDate {
        const dueDate = new Date(timing.getTime());
        dueDate.setDate(dueDate.getDate() + 266);
        return new DueDate(dueDate);
    }

    public static createFromDueDate(dueDate: Date): DueDate {
        return new DueDate(dueDate);
    }

    public get(): Date {
        return this.date;
    }

    public convertTiming(): Date {
        const timing = new Date(this.date.getTime());
        timing.setDate(timing.getDate() - 266);
        return timing;
    }
}

export default DueDate;
