import DueDate from "../domain/DueDate";
import React, {useState} from "react";
import DateForm from "./DateForm";
import DateFormat from "../presentation/DateFormat";
import ListMenu from "./ListMenu";
import {Helmet} from "react-helmet-async";

const FromDue = () => {
    const initDate = new Date().toISOString().substring(0, 10).replace('/', '-');
    const [date, setDate] = useState<string>(initDate);
    const [dueDate, setDueDate] = useState<string>('');
    const [timing, setTiming] = useState<string>('');
    const calcDueDate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const dueDate = DueDate.createFromDueDate(new Date(date));
        const timing = dueDate.convertTiming();
        setTiming(DateFormat.fullDate(timing));
        setDueDate(DateFormat.fullDate(dueDate.get()));
    }
    const message = <span>出産希望日は<br className="disabled:lg:"/>いつですか？</span>
    const pageTitle = '出産希望日から逆算する。'
    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="w-full flex justify-center items-center">
                <div className="bg-neutral text-lightShade px-4 py-16 w-full">
                    <div className="bg-white mx-auto w-11/12 rounded-xl p-5 md:w-6/12 lg:w-4/12">
                        {timing &&
                            <div className="mb-12 pb-12 border-b">
                                <h1 className="text-3xl font-bold tracking-tight mb-12">
						<span className="block ">
							{timing}にタイミングを！
						</span>
                                </h1>
                                <p>
                                    {timing}にタイミングがあうと
                                    {dueDate}が出産予定日になります。
                                </p>
                            </div>
                        }

                        <DateForm message={message} date={date} setDate={setDate} calcDueDate={calcDueDate}/>


                    </div>
                </div>
            </div>

            <ListMenu/>
        </div>
    )
}

export default FromDue
