import DueDate from "../domain/DueDate";
import React, {useState} from "react";
import DateForm from "./DateForm";
import DisplayDueDate from "./DisplayDueDate";
import ListMenu from "./ListMenu";
import {Helmet} from "react-helmet-async";

const FromLastMonth = () => {
    const initDate = new Date().toISOString().substring(0, 10).replace('/', '-');
    const [date, setDate] = useState<string>(initDate);
    const [dueDate, setDueDate] = useState<DueDate | null>(null);
    const calcDueDate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDueDate(DueDate.createFromTiming(new Date(date)));
    }
    const message = <span>最後の生理開始日は<br className="disabled:lg:"/>いつですか？</span>
    const pageTitle = '最後の生理開始日から出産予定日を計算する。'
    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="w-full flex justify-center items-center">
                <div className="bg-neutral text-lightShade px-4 py-16 w-full">
                    <div className="bg-white mx-auto w-11/12 rounded-xl p-5 md:w-6/12 lg:w-4/12">

                        <DisplayDueDate dueDate={dueDate}/>

                        <DateForm message={message} date={date} setDate={setDate} calcDueDate={calcDueDate}/>


                    </div>
                </div>
            </div>
            <ListMenu/>
        </div>
    )
}

export default FromLastMonth
