import React, {ReactElement} from 'react'

type DateFormPropsType = {
    message: ReactElement;
    date: string;
    setDate: React.Dispatch<React.SetStateAction<string>>;
    calcDueDate: (e: React.FormEvent<HTMLFormElement>) => void;
}
const DateForm = ({message, date, setDate, calcDueDate}: DateFormPropsType) => {
    return (
        <div className="mx-0">
            <p className="text-center">
                {message}
            </p>

            <form method="get" onSubmit={calcDueDate}>
                <div className="mt-10">
                    <input type="date" name="date" value={date} onChange={e => setDate(e.target.value)}
                           className="text-3xl w-full text-darkShade" />
                </div>
                <div className="mt-10 text-center">
                    <button className="btn btn-primary">
                        予定日を見る
                    </button>
                </div>
            </form>
        </div>
    )
}

export default DateForm
