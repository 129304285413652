import React from "react";
import DueDate from "../domain/DueDate";
import DateFormat from "../presentation/DateFormat";

const DisplayDueDate = ({dueDate}: { dueDate: DueDate|null }) => {
    if (dueDate === null) {
        return (<></>);
    }
    const formattedDate = DateFormat.fullDate(dueDate.get())
    return (
        <h1 className="text-3xl font-bold tracking-tight mb-12">
            <span className="block ">
                出産予定日は<br/><br/>{formattedDate}<br/><br/>ぐらいです。
            </span>
        </h1>

    )
}

export default DisplayDueDate
